import type { Script } from "@unhead/vue";
import type {
  AboutPage,
  Course,
  EducationalOrganization,
  FAQPage,
  Offer,
  Question,
  NewsArticle as SchemaNewsArticle,
  Thing,
  VideoObject,
  WebPage,
  WithContext,
} from "schema-dts";
import { useUtilityService } from "~/composables/useUtilityService";
import {
  AboutPolicies,
  AboutRankings,
  AboutUs,
  Accreditation,
  Admission,
  AdmissionProfile,
  ContactUs,
  DegreeProgramRequestInfoThankYou,
  DegreeSeekingProgram,
  Faculty,
  Faq,
  FaqPage,
  InterestArea,
  InterestAreaType,
  Maybe,
  MilitaryScholarship,
  NewsArticle,
  NewsCategory,
  NewsroomHome,
  NondegreeAdmission,
  ParagraphFeaturedCourses,
  Persona,
  ProgramCertificateGraduate,
  ProgramExploratory,
  ProgramGraduate,
  ProgramUndergraduate,
  Students,
  StudentsCommunity,
  StudentsCourses,
  StudentsGraduation,
  StudentsReviews,
  StudentsServices,
  StudentsStudyAbroad,
  StudentsSuccessTeam,
  StudentsTutoring,
  Study,
  WhatItCosts,
  WhyAsuo,
} from "~/server/resolvers/resolvers-types";

interface Schedule {
  "@type": "Schedule";
  repeatFrequency: string;
  repeatCount: number;
  CourseWorkload: string;
}

interface CourseInstance {
  "@type": "CourseInstance";
  courseMode: string;
  courseSchedule?: Schedule;
}

export const useSchemaService = () => {
  const UtilityService = useUtilityService();
  const JsonLdNuxtHead = <T extends Thing>(json: WithContext<T>): Script => {
    return {
      type: "application/ld+json",
      innerHTML: JSON.stringify(json),
    };
  };
  const errorScriptValue: Script = {
    type: "script",
    innerHTML: "//schema error, check logs",
  };
  // formats dates to ISO with generic UTC time code added
  const appendIsoToDate = (date: string) => {
    const defaultIsoPostFix = "T09:00:30.000Z";
    return date + defaultIsoPostFix;
  };

  // internal functions
  // interest-area template schema offers
  function generateInterestAreaTemplateOffers(
    interestArea: InterestArea | undefined,
    programs: DegreeSeekingProgram[] | undefined | []
  ): Array<WebPage | SchemaNewsArticle> {
    const itemOffered: Array<WebPage | SchemaNewsArticle> = [];

    if (programs) {
      // degree offers
      programs.forEach((edge) => {
        if (edge && edge.title && edge.shortDescription)
          itemOffered.push({
            //  "@context": "https://schema\.org",
            "@type": "WebPage",
            "@id": UtilityService.formatCanonicalUrl(
              `https://asuonline.asu.edu${edge.detailPage}`
            ),
            name: edge.title,
            description: edge.shortDescription,
          } satisfies WebPage);
        else {
          console.error(
            interestArea?.id,
            "Schema: missing program title or desc."
          );
        }
      });
    } else {
      console.error(
        "Schema: Programs missing while generating interest area schema"
      );
    }

    // news article offers
    interestArea?.relatedNewsArticle?.forEach((article) => {
      if (
        article &&
        article.title &&
        article.coverImage &&
        article.updatedDate
      ) {
        itemOffered.push({
          //  "@context": "https://schema\.org",
          "@type": "NewsArticle",
          publisher: getOrganizationSchema(),
          headline: article.title,
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": UtilityService.formatCanonicalUrl(
              `https://asuonline.asu.edu${article.detailPage}`
            ),
          },
          image: article.coverImage,
          datePublished: article.publishedDate
            ? appendIsoToDate(article.publishedDate)
            : new Date().toISOString(),
          dateModified: appendIsoToDate(article.updatedDate),
          author: getOrganizationSchema(),
        } satisfies SchemaNewsArticle);
      } else {
        if (!article?.title)
          console.error(
            interestArea.id,
            "Schema: Interest area missing news article title."
          );
        if (!article?.coverImage)
          console.error(
            interestArea.id,
            "Schema: Interest area missing news article cover image."
          );
        if (!article?.updatedDate)
          console.error(
            interestArea.id,
            "Schema: Interest area missing news article updated date."
          );
      }
    });

    return itemOffered;
  }

  // program template schema offers
  function generateProgramTemplateOffers(
    featuredCourseItems: Maybe<ParagraphFeaturedCourses>[],
    totalCreditHours: string,
    repeatCount: number
  ): Offer {
    const offeredItems: Array<Course | VideoObject> = [];
    featuredCourseItems.forEach((item) => {
      if (item && item.title) {
        offeredItems.push({
          "@type": "Course",
          name: item.title,
          description: item.description ? item.description : item.title,
          provider: getOrganizationSchema(),
          offers: {
            "@type": "Offer",
            category: "Subscription",
          },
          hasCourseInstance: [
            {
              "@type": "CourseInstance",
              courseMode: "Online",
              courseSchedule: {
                "@type": "Schedule",
                repeatFrequency: "Weekly",
                repeatCount: repeatCount,
                CourseWorkload:
                  "P" + 3 * (Number(totalCreditHours) * repeatCount) + "H",
              },
            },
          ] as CourseInstance[],
        } as Course);
      } else {
        console.error(item?.id, "Schema: Program offer missing course title.");
      }

      if (item && item.youtubeVideoId && item.youtubeVideoUploadDate) {
        offeredItems.push({
          "@type": "VideoObject",
          name: item.title,
          description: item.description ? item.description : item.title,
          duration: item.youtubeVideoDuration,
          uploadDate: appendIsoToDate(item.youtubeVideoUploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${item.youtubeVideoId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${item.youtubeVideoId}`,
          offers: {
            "@type": "Offer",
            category: "Subscription",
          },
        } as VideoObject);
      }
    });

    return {
      "@type": "Offer",
      itemOffered: offeredItems,
    };
  }

  // asuonline orgranization schema
  function getOrganizationSchema() {
    return {
      "@type": "EducationalOrganization",
      name: "ASU Online",
      email: "asuonline@asu.edu",
      telephone: "(480) 884-1525",
      description:
        "Learn more about Arizona State University's 100% online programs, applying to ASU, admission, and available financial aid.",
      url: "https://asuonline.asu.edu",
      logo: {
        "@type": "ImageObject",
        url: "https://www.asu.edu/asuthemes/4.8/assets/full_logo.png",
      },
      sameAs: [
        "https://twitter.com/asuonline",
        "https://facebook.com/asuonline",
        "https://www.linkedin.com/showcase/3693076/",
        "https://instagram.com/asuonline",
        "https://www.pinterest.com/asuonline",
      ],
      address: {
        "@type": "PostalAddress",
        addressCountry: "USA",
        addressLocality: "Scottsdale",
        addressRegion: "Arizona",
        postalCode: "85257",
        streetAddress: "1365 N. Scottsdale Rd., Skysong 200",
      },
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "+1-855-278-5080",
          contactType: "help desk",
          contactOption: "TollFree",
          areaServed: "US",
        },
        {
          "@type": "ContactPoint",
          telephone: "+1-866-277-6589",
          contactType: "Admissions",
          contactOption: "TollFree",
          areaServed: "US",
        },
      ],
      parentOrganization: {
        "@type": "EducationalOrganization",
        name: "Arizona State University",
        description:
          "Arizona State University is a public metropolitan research university on five campuses across the Phoenix metropolitan area, and four regional learning centers throughout Arizona. ASU is one of the largest public universities by enrollment in the U.S.",
        leiCode: "254900BLZ1MCZ5JLK411",
        url: "https://www.asu.edu/",
        telephone: "480-965-2100",
        sameAs: [
          "https://twitter.com/asu",
          "https://www.facebook.com/arizonastateuniversity",
          "https://www.instagram.com/arizonastateuniversity/",
          "https://www.youtube.com/user/ASU",
        ],
        logo: {
          "@type": "ImageObject",
          url: "https://www.asu.edu/asuthemes/4.8/assets/full_logo.png",
        },
      },
    } as EducationalOrganization;
  }

  function generateBreadcrumbName(metaTitle: string) {
    return metaTitle.split("|")[0].trim();
  }

  // export functions

  // /web-analytics-privacy-2/ page's schema
  function generateWebAnalyticsPrivacy2Schema(
    title: string,
    metaTitle: string,
    metaDescription: string
  ) {
    const pageUrl = UtilityService.formatCanonicalUrl(
      `https://asuonline.asu.edu/web-analytics-privacy-2`
    );

    return JsonLdNuxtHead<WebPage>({
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: title,
      description: metaDescription,
      url: pageUrl,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "ASU Online",
            item: "https://asuonline.asu.edu/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: generateBreadcrumbName(metaTitle),
            item: pageUrl,
          },
        ],
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": pageUrl,
      },
      provider: getOrganizationSchema(),
    });
  }

  // /first-time-freshman-registration-rules/ page's schema
  function generateFirstTimeFreshmanRegistrationRulesSchema(pageData: any) {
    const pageUrl = UtilityService.formatCanonicalUrl(
      `https://asuonline.asu.edu${pageData.path}`
    );

    return JsonLdNuxtHead<WebPage>({
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: pageData.sectionIntroduction.title,
      description: pageData.meta.description,
      url: pageUrl,
      image: pageData.sectionHero.image,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "ASU Online",
            item: "https://asuonline.asu.edu/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: generateBreadcrumbName(pageData.meta.title),
            item: pageUrl,
          },
        ],
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": pageUrl,
      },
      provider: getOrganizationSchema(),
    });
  }

  // /degree-program-request-info-thank-you/ page's schema
  function generateDegreeProgramRequestInfoThankYouSchema(
    pageData: DegreeProgramRequestInfoThankYou | undefined
  ) {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.sectionBg &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );
      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.sectionBg,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Degree Prorgam thank you page data missing.");

      if (!pageData?.title)
        console.error("Schema: Degree Prorgam thank you title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Degree Prorgam thank you description missing.");
      if (!pageData?.sectionBg)
        console.error("Schema: Degree Prorgam thank you image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Degree Prorgam thank you breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /about-us page's schema
  function generateAboutUsSchema(pageData: AboutUs | undefined) {
    if (
      pageData &&
      pageData.heroTitle &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle &&
      pageData.dedicatedToSuccess?.title &&
      pageData.dedicatedToSuccess?.duration &&
      pageData.dedicatedToSuccess?.uploadDate &&
      pageData.dedicatedToSuccess?.youtubeId
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );
      return JsonLdNuxtHead<AboutPage>({
        "@context": "https://schema.org",
        "@type": "AboutPage",
        name: pageData.heroTitle,
        description: pageData.metaDescription,
        url: url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        reviewedBy: getOrganizationSchema(),
        mainEntityOfPage: {
          "@type": "AboutPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: [
            {
              "@type": "VideoObject",
              name: pageData.dedicatedToSuccess?.title,
              description: pageData.dedicatedToSuccess?.description
                ? pageData.dedicatedToSuccess?.description
                : pageData.dedicatedToSuccess?.title,
              duration: pageData.dedicatedToSuccess?.duration,
              uploadDate: appendIsoToDate(
                pageData.dedicatedToSuccess?.uploadDate
              ),
              thumbnailUrl: `https://img.youtube.com/vi/${pageData.dedicatedToSuccess?.youtubeId}/0.jpg`,
              contentUrl: `https://www.youtube.com/watch?v=${pageData.dedicatedToSuccess?.youtubeId}`,
            },
          ],
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: about us page data missing.");

      if (!pageData?.heroTitle)
        console.error("Schema: about us page missing title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: about us page missing desc. missing.");
      if (!pageData?.heroImage)
        console.error("Schema: about us page missing hero missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: about us page missing breadcrumb missing.");
      if (!pageData?.dedicatedToSuccess?.title)
        console.error("Schema: about us page missing video title missing.");
      if (!pageData?.dedicatedToSuccess?.duration)
        console.error("Schema: about us page missing video duration missing.");
      if (!pageData?.dedicatedToSuccess?.uploadDate)
        console.error(
          "Schema: about us page missing video upload date missing."
        );
      if (!pageData?.dedicatedToSuccess?.youtubeId)
        console.error("Schema: about us page missing video id missing.");

      return errorScriptValue;
    }
  }

  // /about-us/faq/ page's schema
  function generateAboutUsFaqSchema(
    pageData: FaqPage | undefined,
    faqItems: Faq[] | undefined
  ) {
    if (
      pageData &&
      pageData?.title &&
      pageData?.metaDescription &&
      pageData?.heroImage &&
      pageData?.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );
      const aboutFaqItems: Question[] = [];
      if (faqItems) {
        faqItems.forEach((item) => {
          if (item && item.title && item.description) {
            aboutFaqItems.push({
              "@type": "Question",
              name: item.title,
              acceptedAnswer: {
                "@type": "Answer",
                text: item.description,
              },
            });
          } else {
            console.error("Schema: faq item missing title or description.");
          }
        });
      } else {
        console.error("Schema: missing faq items in faq schema");
      }

      return JsonLdNuxtHead<FAQPage>({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntity: aboutFaqItems,
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: missing FAQ page data.");

      if (!pageData?.title)
        console.error("Schema: missing title missing in faq schema.");
      if (!pageData?.metaDescription)
        console.error("Schema: missing description missing in faq schema.");
      if (!pageData?.heroImage)
        console.error("Schema: missing image missing in faq schema.");
      if (!pageData?.metaTitle)
        console.error("Schema: missing breadcrumb missing in faq schema.");

      return errorScriptValue;
    }
  }

  // /about-us/policies/ page's schema
  function generateAboutUsPoliciesSchema(pageData: AboutPolicies | undefined) {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "About Us",
              item: `https://asuonline.asu.edu/about-us/`,
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: policies page data missing.");

      if (!pageData?.title) console.error("Schema: policies title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: policies description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: policies image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: policies breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /about-us/accreditation/ page's schema
  function generateAboutUsAccreditationSchema(
    pageData: Accreditation | undefined
  ) {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: Array<VideoObject | WebPage> = [];
      if (
        pageData.accreditationVideo &&
        pageData.accreditationVideo.title &&
        pageData.accreditationVideo.duration &&
        pageData.accreditationVideo.uploadDate
      ) {
        let videoDescription = "";
        if (pageData.accreditationVideo.description) {
          videoDescription = pageData.accreditationVideo.description;
        } else if (pageData.accreditationVideo.title) {
          videoDescription = pageData.accreditationVideo.title;
        }
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.accreditationVideo.title,
          description: videoDescription,
          duration: pageData.accreditationVideo.duration,
          uploadDate: appendIsoToDate(pageData.accreditationVideo.uploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${pageData.accreditationVideo?.youtubeId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.accreditationVideo?.youtubeId}`,
        } satisfies VideoObject);
      } else {
        if (!pageData.accreditationVideo)
          console.error("Schema: accreditation video data missing.");

        if (!pageData?.accreditationVideo?.title)
          console.error("Schema: accreditation video title missing.");
        if (!pageData?.accreditationVideo?.duration)
          console.error("Schema: accreditation video description missing.");
        if (!pageData?.accreditationVideo?.uploadDate)
          console.error("Schema: accreditation video upload date missing.");
      }

      if (pageData.authorizationCards) {
        pageData.authorizationCards.forEach((item) => {
          if (
            item &&
            item.link &&
            item.link.target &&
            item.link.uri &&
            item.title
          ) {
            const formatCtaLink = () => {
              if (item.link!.target === "_self") {
                return `https://asuonline.asu.edu${item.link!.uri}`;
              } else {
                return item?.link?.uri!;
              }
            };

            pageOffers.push({
              "@type": "WebPage",
              "@id": formatCtaLink(),
              name: item?.title,
            } satisfies WebPage);
          }
        });
      }

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "About ASU Online",
              item: "https://asuonline.asu.edu/about-us/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: accreditation page data missing.");

      if (!pageData?.title)
        console.error("Schema: accreditation title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: accreditation description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: accreditation image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: accreditation breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /about-us/contact-us/ page's schema
  function generateAboutUsContactSchema(pageData: ContactUs | undefined) {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "About Us",
              item: "https://asuonline.asu.edu/about-us/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: contact us page data missing.");

      if (!pageData?.title) console.error("Schema: contact us title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: contact us description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: contact us image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: contact us breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /about-us/faculty/ page's schema
  const generateAboutUsFacultySchema = (pageData: Faculty | undefined) => {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: Array<VideoObject | WebPage | SchemaNewsArticle> = [];
      if (
        pageData.facultyVideo &&
        pageData.facultyVideo.title &&
        pageData.facultyVideo.title &&
        pageData.facultyVideo.duration &&
        pageData.facultyVideo.uploadDate &&
        pageData.facultyVideo.imageSource
      ) {
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.facultyVideo.title,
          description: pageData.facultyVideo.description
            ? pageData.facultyVideo.description
            : pageData.facultyVideo.title,
          duration: pageData.facultyVideo.duration,
          uploadDate: appendIsoToDate(pageData.facultyVideo.uploadDate),
          thumbnailUrl: pageData.facultyVideo.imageSource,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.facultyVideo.youtubeId}`,
        });
      } else {
        if (!pageData.facultyVideo)
          console.error("Schema: faculty video data missing.");

        if (!pageData.facultyVideo?.title)
          console.error("Schema: faculty video title missing.");
        if (!pageData.facultyVideo?.description)
          console.error("Schema: faculty video description missing.");
        if (!pageData.facultyVideo?.duration)
          console.error("Schema: faculty video upload date missing.");
        if (!pageData.facultyVideo?.uploadDate)
          console.error("Schema: faculty video upload date missing.");
        if (!pageData.facultyVideo?.imageSource)
          console.error("Schema: faculty video upload date missing.");
      }
      if (pageData.researchCards) {
        pageData.researchCards.forEach((item) => {
          if (item && item.link && item.link.uri && item.title) {
            pageOffers.push({
              "@type": "WebPage",
              "@id": item.link.uri,
              name: item.title,
            });
          } else {
            console.error(
              "Schema: research cards in faculty page missing title or link uri."
            );
          }
        });
      }

      if (pageData.newsArticles) {
        pageData.newsArticles.forEach((item) => {
          if (item?.detailPage && item.title) {
            pageOffers.push({
              "@type": "NewsArticle",
              "@id": `https://asuonline.asu.edu${item.detailPage}`,
              name: item.title,
            });
          } else {
            console.error(
              "Schema: news article in faculty page missing title or detail page."
            );
          }
        });
      }

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "About ASU Online",
              item: "https://asuonline.asu.edu/about-us/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: faculty page page data missing.");

      if (!pageData?.title)
        console.error("Schema: faculty page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: faculty page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: faculty page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: faculty page breadcrumb missing.");

      return errorScriptValue;
    }
  };

  // /about-us/rankings page's schema
  function generateAboutUsRankingsSchema(pageData: AboutRankings | undefined) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.heroTitle &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const faqItems: Question[] = [];
      if (pageData.faqItems && Array.isArray(pageData.faqItems)) {
        pageData.faqItems.forEach((faqItem) => {
          if (faqItem?.title && faqItem.description) {
            faqItems.push({
              "@type": "Question",
              name: faqItem.title,
              acceptedAnswer: {
                "@type": "Answer",
                text: faqItem.description,
              },
            });
          } else {
            console.error("Schema: FAQ item missing title or description.");
          }
        });
      }

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.heroTitle,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "About ASU Online",
              item: "https://asuonline.asu.edu/about-us/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        reviewedBy: getOrganizationSchema(),
        mainEntityOfPage: {
          "@type": "AboutPage",
          "@id": url,
        },
        mainEntity: {
          "@type": "FAQPage",
          mainEntityOfPage: url,
          name: pageData.heroTitle,
          description: pageData.metaDescription,
          url: url,
          image: pageData.heroImage,
          mainEntity: faqItems,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: rankings page page data missing.");

      if (!pageData?.heroTitle)
        console.error("Schema: rankings page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: rankings page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: rankings page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: rankings page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /about-us/why-asuo/ page's schema
  const generateAboutUsWhyAsuoSchema = (pageData: WhyAsuo | undefined) => {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "About Us",
              item: "https://asuonline.asu.edu/about-us/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Why Asuo page data missing.");

      if (!pageData?.title)
        console.error("Schema: Why Asuo page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Why Asuo page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Why Asuo page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Why Asuo page breadcrumb missing.");

      return errorScriptValue;
    }
  };

  // /online-degree-programs/[category]/ page's schema
  function generateCategorySearchSchema(
    category: string,
    title: string,
    description: string,
    programs: Maybe<Maybe<DegreeSeekingProgram>[]> | undefined
  ) {
    const url = `https://asuonline.asu.edu/online-degree-programs/${category}/`;
    const pageOffers: WebPage[] = [];
    if (programs && programs?.length > 0) {
      programs.forEach((program) => {
        if (
          program &&
          program.detailPage &&
          program.alternateTitle &&
          program.degreeImage
        ) {
          const offerUrl = UtilityService.formatCanonicalUrl(
            `https://asuonline.asu.edu${program.detailPage}/`
          );
          pageOffers.push({
            "@type": "WebPage",
            "@id": offerUrl,
            name: program.alternateTitle,
            url: offerUrl,
            image: program.degreeImage,
          });
        } else {
          console.error(
            "Schema: missing program title or image in category schema."
          );
        }
      });
    } else {
      console.error("Schema: programs are missing in category schema.");
    }

    return JsonLdNuxtHead<WebPage>({
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: title,
      description: description,
      url,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "ASU Online",
            item: "https://asuonline.asu.edu/",
          },
          {
            "@type": "ListItem",
            position: 1,
            name: "All Online Degree Programs",
            item: "https://asuonline.asu.edu/online-degree-programs/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: generateBreadcrumbName(title),
            item: url,
          },
        ],
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": url,
      },
      offers: {
        "@type": "Offer",
        itemOffered: pageOffers,
      },
      provider: getOrganizationSchema(),
    });
  }

  // /online-degree-programs/ page's schema
  function generateAllDegreeSearchSchema(
    title: string,
    description: string,
    detailPage: string,
    programs: DegreeSeekingProgram[] | undefined
  ) {
    const pageUrl = `https://asuonline.asu.edu${detailPage}/`;
    const pageOffers: Array<WebPage> = [];
    if (programs && programs.length > 0) {
      programs.forEach((program) => {
        if (
          program &&
          program.detailPage &&
          program.alternateTitle &&
          program.degreeImage
        ) {
          const offerUrl = UtilityService.formatCanonicalUrl(
            `https://asuonline.asu.edu${program.detailPage}/`
          );
          pageOffers.push({
            "@type": "WebPage",
            "@id": offerUrl,
            name: program.alternateTitle,
            url: offerUrl,
            image: program.degreeImage,
          });
        } else {
          console.error(
            "Schema: program missing title or image in degree search schema.",
            program.detailPage,
            program.alternateTitle,
            program.degreeImage
          );
        }
      });
    } else {
      console.error("Schema: missing programs in degree search schema.");
    }
    return JsonLdNuxtHead<WebPage>({
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: title,
      description: description,
      url: pageUrl,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "ASU Online",
            item: "https://asuonline.asu.edu/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: generateBreadcrumbName(title),
            item: pageUrl,
          },
        ],
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": pageUrl,
      },
      offers: {
        "@type": "Offer",
        itemOffered: pageOffers,
      },
      provider: getOrganizationSchema(),
    });
  }

  // /students/community/ page's schema
  function generateStudentsCommunitySchema(
    pageData: StudentsCommunity | undefined
  ) {
    if (
      pageData &&
      pageData.connectionLink &&
      pageData.connectionLink.uri &&
      pageData.connectionTitle &&
      pageData.studentCards &&
      pageData.studentCards.length > 0 &&
      pageData.exploreCards &&
      pageData.exploreCards.length > 0 &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: WebPage[] = [
        {
          "@type": "WebPage",
          "@id": pageData.connectionLink.uri,
          name: pageData.connectionTitle,
        },
      ];

      pageData.studentCards.forEach((item) => {
        if (item?.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": item.link.uri,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: student card in student community page missing uri or title."
          );
        }
      });

      pageData.exploreCards.forEach((item) => {
        if (item?.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.link.uri}`,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: student card in student community page missing uri or title."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Student Services",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Sudents Community page data missing.");

      if (!pageData?.connectionLink)
        console.error(
          "Schema: Sudents Community page connection link missing."
        );
      if (!pageData?.connectionTitle)
        console.error(
          "Schema: Sudents Community page connection title missing."
        );
      if (!pageData?.studentCards)
        console.error("Schema: Sudents Community page student cards missing.");
      if (!pageData?.exploreCards)
        console.error("Schema: Sudents Community page explore cards missing.");
      if (!pageData?.title)
        console.error("Schema: Sudents Community page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Sudents Community page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Sudents Community page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Sudents Community page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /students/graduation-beyond/ page's schema
  function generateStudentsGraduationSchema(
    pageData: StudentsGraduation | undefined
  ) {
    if (
      pageData &&
      pageData.video &&
      pageData.video.title &&
      pageData.video.duration &&
      pageData.video.uploadDate &&
      pageData.exploreCards &&
      pageData.exploreCards.length > 0 &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: Array<VideoObject | WebPage> = [];
      pageOffers[0] = {
        "@type": "VideoObject",
        name: pageData.video.title,
        description: pageData.video.description
          ? pageData.video.description
          : pageData.video.title,
        duration: pageData.video.duration,
        uploadDate: appendIsoToDate(pageData.video.uploadDate),
        thumbnailUrl: `https://img.youtube.com/vi/${pageData.video.youtubeId}/0.jpg`,
        contentUrl: `https://www.youtube.com/watch?v=${pageData.video.youtubeId}`,
      };

      pageData.exploreCards.forEach((item) => {
        if (item?.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.link.uri}`,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: Sudents Graduation page explore card missing title or uri."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Students",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Sudents Graduation page data missing.");

      if (!pageData?.exploreCards)
        console.error("Schema: Sudents Graduation page explore cards missing.");
      if (!pageData?.title)
        console.error("Schema: Sudents Graduation page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Sudents Graduation page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Sudents Graduation page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Sudents Graduation page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /students/tutoring/ page's schema
  function generateStudentsTutoringSchema(
    pageData: StudentsTutoring | undefined
  ) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.exploreCards &&
      pageData.exploreCards.length > 0 &&
      pageData.subjectCards &&
      pageData.subjectCards.length > 0 &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: WebPage[] = [];
      pageData.exploreCards.forEach((item) => {
        if (item?.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.link.uri}`,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: Sudents Tutoring page explore card missing title or uri."
          );
        }
      });

      pageData.subjectCards.forEach((item) => {
        if (item?.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": item.link.uri,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: Sudents Tutoring page subject card missing title or uri."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Student Services",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Sudents Tutoring page data missing.");

      if (!pageData?.exploreCards)
        console.error("Schema: Sudents Tutoring page explore cards missing.");
      if (!pageData?.subjectCards)
        console.error("Schema: Sudents Tutoring page subject cards missing.");
      if (!pageData?.title)
        console.error("Schema: Sudents Tutoring page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Sudents Tutoring page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Sudents Tutoring page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Sudents Tutoring page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /students/reviews/ page's schema
  function generateStudentsReviewsSchema(
    pageData: StudentsReviews | undefined
  ) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url,
        image: pageData.heroImage || undefined,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Student Services",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Sudents Reviews page data missing.");
      if (!pageData?.title)
        console.error("Schema: Sudents Reviews page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Sudents Reviews page description missing.");
      // if (!pageData?.heroImage)
      //   console.error("Schema: Sudents Reviews page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Sudents Reviews page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /students/services/ page's schema
  function generateStudentsServicesSchema(
    pageData: StudentsServices | undefined
  ) {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle &&
      pageData.exploreCards &&
      pageData.exploreCards.length > 0
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: WebPage[] = [];
      pageData.exploreCards.forEach((item) => {
        if (item?.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.link.uri}`,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: Students Services page explore card missing title or uri."
          );
        }
      });

      const faqItems: Question[] = [];
      if (pageData.faqItems && Array.isArray(pageData.faqItems)) {
        pageData.faqItems.forEach((faqItem) => {
          if (faqItem?.title && faqItem.description) {
            faqItems.push({
              "@type": "Question",
              name: faqItem.title,
              acceptedAnswer: {
                "@type": "Answer",
                text: faqItem.description,
              },
            });
          } else {
            console.error("Schema: FAQ item missing title or description.");
          }
        });
      }

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Student Services",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        // Include FAQ schema inside the `mainEntity` field
        mainEntity: {
          "@type": "FAQPage",
          mainEntityOfPage: pageUrl,
          name: pageData.title,
          description: pageData.metaDescription,
          url: pageUrl,
          image: pageData.heroImage,
          mainEntity: faqItems,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) {
        console.error("Schema: Students Services page data missing.");
      }
      if (!pageData?.exploreCards) {
        console.error("Schema: Students Services page explore cards missing.");
      }
      if (!pageData?.title) {
        console.error("Schema: Students Services page title missing.");
      }
      if (!pageData?.metaDescription) {
        console.error("Schema: Students Services page description missing.");
      }
      if (!pageData?.heroImage) {
        console.error("Schema: Students Services page image missing.");
      }
      if (!pageData?.metaTitle) {
        console.error("Schema: Students Services page breadcrumb missing.");
      }

      return errorScriptValue;
    }
  }

  // /students/success-team page's schema
  function generateStudentsSuccessTeamSchema(
    pageData: StudentsSuccessTeam | undefined
  ) {
    if (
      pageData &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle &&
      pageData.newsArticles
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: SchemaNewsArticle[] = [];

      // news article offers
      pageData.newsArticles.forEach((article) => {
        if (
          article &&
          article.title &&
          article.detailPage &&
          article.coverImage &&
          article.updatedDate
        ) {
          pageOffers.push({
            "@type": "NewsArticle",
            publisher: getOrganizationSchema(),
            headline: article.title,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": UtilityService.formatCanonicalUrl(
                `https://asuonline.asu.edu${article.detailPage}`
              ),
            },
            image: article.coverImage,
            datePublished: article.created
              ? appendIsoToDate(article.created)
              : new Date().toISOString(),
            dateModified: appendIsoToDate(article.updatedDate),
            author: getOrganizationSchema(),
          });
        } else {
          if (!article)
            console.error("Schema: Sudents Success page articles missing.");

          if (!article?.title)
            console.error(
              "Schema: Sudents Success page article missing title."
            );
          if (!article?.detailPage)
            console.error(
              "Schema: Sudents Success page article missing detail page."
            );
          if (!article?.coverImage)
            console.error(
              "Schema: Sudents Success page article missing image."
            );
          if (!article?.updatedDate)
            console.error(
              "Schema: Sudents Success page article missing updated date."
            );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Student Services",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Sudents Success Team page data missing.");

      if (!pageData?.newsArticles)
        console.error(
          "Schema: Sudents Success Team page subject articles missing."
        );
      if (!pageData?.title)
        console.error("Schema: Sudents Success Team page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Sudents Success Team page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Sudents Success Team page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Sudents Success Team page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /students/courses/ page's schema
  function generateStudentsCourseSchema(pageData: StudentsCourses | undefined) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.videoCard &&
      pageData.exploreCards &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: Array<VideoObject | WebPage> = [];

      if (
        pageData.videoCard.title &&
        pageData.videoCard.duration &&
        pageData.videoCard.uploadDate &&
        pageData.videoCard.youtubeId
      ) {
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.videoCard.title,
          description: pageData.videoCard.description
            ? pageData.videoCard.description
            : pageData.videoCard.title,
          duration: pageData.videoCard.duration,
          uploadDate: appendIsoToDate(pageData.videoCard.uploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${pageData.videoCard.youtubeId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.videoCard.youtubeId}`,
        });
      } else {
        console.error(
          "Schema: Students courses video card missing required fields.",
          pageData.videoCard.title,
          pageData.videoCard.duration,
          pageData.videoCard.uploadDate,
          pageData.videoCard.youtubeId
        );
      }

      if (
        pageData.videoSection &&
        pageData.videoSection.title &&
        pageData.videoSection.duration &&
        pageData.videoSection.uploadDate &&
        pageData.videoSection.youtubeId
      ) {
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.videoSection.title,
          description: pageData.videoSection.description
            ? pageData.videoSection.description
            : pageData.videoSection.title,
          duration: pageData.videoSection.duration,
          uploadDate: appendIsoToDate(pageData.videoSection.uploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${pageData.videoSection.youtubeId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.videoSection.youtubeId}`,
        });
      } else {
        if (pageData.videoSection) {
          console.error(
            "Schema: Students courses video section missing title, duration, upload date, or youtube id."
          );
        }
      }
      pageData.exploreCards.forEach((item) => {
        if (item && item.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": item.link.uri,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: Students courses explore card missing title or uri."
          );
        }
      });

      // FAQ Section
      const faqItems: Question[] = [];
      if (pageData.faqItems && Array.isArray(pageData.faqItems)) {
        pageData.faqItems.forEach((faqItem) => {
          if (faqItem?.title && faqItem.description) {
            faqItems.push({
              "@type": "Question",
              name: faqItem.title,
              acceptedAnswer: {
                "@type": "Answer",
                text: faqItem.description,
              },
            });
          } else {
            console.error("Schema: FAQ item missing title or description.");
          }
        });
      }

      // Return the final schema with FAQ included
      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Students",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        // Add FAQ section to schema if there are FAQ items
        // Include FAQ schema inside the `mainEntity` field
        mainEntity: {
          "@type": "FAQPage",
          mainEntityOfPage: pageUrl,
          name: pageData.title,
          description: pageData.metaDescription,
          url: pageUrl,
          image: pageData.heroImage,
          mainEntity: faqItems,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Students courses page data missing.");
      if (!pageData?.detailPage)
        console.error("Schema: Students courses page detail page missing.");
      if (!pageData?.videoCard)
        console.error("Schema: Students courses page video card missing.");
      if (!pageData?.exploreCards)
        console.error("Schema: Students courses page explore cards missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Students courses page meta title missing.");
      if (!pageData?.title)
        console.error("Schema: Students courses page headline missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Students courses page desc. missing.");
      return errorScriptValue;
    }
  }

  // /students/study-abroad/ page's schema
  function generateStudyAbroadSchema(
    pageData: StudentsStudyAbroad | undefined
  ) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.questionAccordions &&
      pageData.questionAccordions.length > 0 &&
      pageData.exploreCards &&
      pageData.exploreCards.length > 0 &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle &&
      pageData.video
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const studyAbroadQuestions: Question[] = [];
      pageData.questionAccordions.forEach((item) => {
        if (item && item.title && item.description) {
          studyAbroadQuestions.push({
            "@type": "Question",
            name: item.title,
            acceptedAnswer: {
              "@type": "Answer",
              text: item.description,
            },
          });
        } else {
          console.error(
            "Schema: Study abroad question accordion item missing title or description."
          );
        }
      });

      const pageOffers: Array<VideoObject | WebPage> = [];
      if (
        pageData.video.title &&
        pageData.video.duration &&
        pageData.video.uploadDate &&
        pageData.video.youtubeId
      ) {
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.video.title,
          description: pageData.video.description
            ? pageData.video.description
            : pageData.video.title,
          duration: pageData.video.duration,
          uploadDate: appendIsoToDate(pageData.video.uploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${pageData.video.youtubeId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.video.youtubeId}`,
        });
      } else {
        console.error(
          "Schema: Study abroad video missing title, duration, upload date, or youtube id."
        );
      }

      pageData.exploreCards.forEach((item) => {
        if (item && item.link && item?.link?.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.link.uri}`,
            name: item.title,
          });
        } else {
          console.error(
            "Schema: Study abroad explore card missing title and uri."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Students",
              item: "https://asuonline.asu.edu/students/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntity: studyAbroadQuestions,
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Study abroad page data missing.");

      if (!pageData?.detailPage)
        console.error("Schema: Study abroad detail page missing.");
      if (!pageData?.title)
        console.error("Schema: Study abroad title missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Study abroad hero meta title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Study abroad description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Study abroad image missing.");
      if (!pageData?.exploreCards)
        console.error("Schema: Study abroad explore cards missing.");
      if (!pageData?.questionAccordions)
        console.error("Schema: Study abroad accordion questions missing.");
      if (!pageData?.video)
        console.error("Schema: Study abroad video missing.");

      return errorScriptValue;
    }
  }

  // /students/ page's schema
  function generateStudentsSchema(pageData: Students | undefined) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.steps &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: Array<VideoObject | WebPage> = [];
      if (
        pageData.video &&
        pageData.video.title &&
        pageData.video.description &&
        pageData.video.duration &&
        pageData.video.uploadDate &&
        pageData.video.youtubeId
      ) {
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.video.title,
          description: pageData.video.description,
          duration: pageData.video.duration,
          uploadDate: appendIsoToDate(pageData.video.uploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${pageData.video.youtubeId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.video.youtubeId}`,
        });
      } else {
        console.error("Schema: Students page missing video data in schema.");
      }
      pageData.steps.forEach((item) => {
        if (item && item.title && item.cta && item.cta.uri && item.text) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.cta.uri}`,
            name: item.title,
            description: item.text,
          });
        } else {
          console.error("Schema: Students page step missing data in schema.");
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Sudents page data missing.");

      if (!pageData?.steps)
        console.error("Schema: Sudents page steps missing.");
      if (!pageData?.title)
        console.error("Schema: Sudents page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Sudents page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Sudents page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Sudents page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // /what-it-costs/ page's schema
  function generateWhatItCostsPageSchema(pageData: WhatItCosts | undefined) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.metaTitle &&
      pageData.heroImage
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: VideoObject[] = [];
      // contact items
      if (
        pageData.video &&
        pageData.video.title &&
        pageData.video.description &&
        pageData.video.duration &&
        pageData.video.uploadDate &&
        pageData.video.youtubeId &&
        pageData.video.youtubeId
      ) {
        pageOffers.push({
          "@type": "VideoObject",
          name: pageData.video.title,
          description: pageData.video.description,
          duration: pageData.video.duration,
          uploadDate: appendIsoToDate(pageData.video.uploadDate),
          thumbnailUrl: `https://img.youtube.com/vi/${pageData.video.youtubeId}/0.jpg`,
          contentUrl: `https://www.youtube.com/watch?v=${pageData.video.youtubeId}`,
        });
      } else {
        console.error("Schema: What it costs video data missing.");
      }

      // FAQ Section
      const faqItems: Question[] = [];
      if (pageData.faqList && Array.isArray(pageData.faqList)) {
        pageData.faqList.forEach((faqList) => {
          if (faqList?.title && faqList.description) {
            faqItems.push({
              "@type": "Question",
              name: faqList.title,
              acceptedAnswer: {
                "@type": "Answer",
                text: faqList.description,
              },
            });
          } else {
            console.error("Schema: FAQ item missing title or description.");
          }
        });
      }

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        // Add FAQ section to schema if there are FAQ items
        // Include FAQ schema inside the `mainEntity` field
        mainEntity: {
          "@type": "FAQPage",
          mainEntityOfPage: pageUrl,
          name: pageData.title,
          description: pageData.metaDescription,
          url: pageUrl,
          image: pageData.heroImage,
          mainEntity: faqItems,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: What it costs page data missing.");

      if (!pageData?.title)
        console.error("Schema: What it costs page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: What it costs page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: What it costs page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: What it costs page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // nondegree page's schema
  function generateNonDegreePageSchema(
    pageData: NondegreeAdmission | undefined
  ) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        image: pageData.heroImage,
        url,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Admission Requirements",
              item: "https://asuonline.asu.edu/admission/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Nondegree page data missing.");

      if (!pageData?.detailPage)
        console.error("Schema: Nondegree page title missing.");
      if (!pageData?.title)
        console.error("Schema: Nondegree page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Nondegree page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Nondegree page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Nondegree page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // military-scholarship page's schema
  function generateMilitaryScholarshipPageSchema(
    pageData: MilitaryScholarship | undefined
  ) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.title &&
      pageData.metaDescription &&
      pageData.heroImage &&
      pageData.metaTitle
    ) {
      const url = `https://asuonline.asu.edu${pageData.detailPage}`;

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.metaDescription,
        image: pageData.heroImage,
        url,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: generateBreadcrumbName(pageData.metaTitle),
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData)
        console.error("Schema: Military scholarship page data missing.");

      if (!pageData?.title)
        console.error("Schema: Military scholarship page title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Military scholarship page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Military scholarship page image missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Military scholarship page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // admission template page's schema
  const generateAdmissionProfileSchema = (
    pageData: AdmissionProfile | undefined | null
  ) => {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.title &&
      pageData.introductionDescription &&
      pageData.heroImage &&
      pageData.heroTitle &&
      pageData.contentHub &&
      pageData.contentHub.title
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.introductionDescription,
        url,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Admission Requirements",
              item: "https://asuonline.asu.edu/admission/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: pageData.title,
              item: `https://asuonline.asu.edu${pageData.contentHub.title}`,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Admission profile data missing.");
      if (!pageData?.detailPage)
        console.error("Schema: Admission profile detail page missing.");
      if (!pageData?.title)
        console.error("Schema: Admission profile title missing.");
      if (!pageData?.heroTitle)
        console.error("Schema: Admission profile hero title missing.");
      if (!pageData?.introductionDescription)
        console.error("Schema: Admission profile description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Admission profile image missing.");
      if (!pageData?.contentHub)
        console.error("Schema: Admission profile breadcrumb missing.");
      if (!pageData?.contentHub?.title)
        console.error("Schema: Admission profile breadcrumb title missing.");

      return errorScriptValue;
    }
  };

  // admission page's schema
  function generateAdmissionPageSchema(pageData: Admission | undefined) {
    if (
      pageData &&
      pageData.metaDescription &&
      pageData.metaTitle &&
      pageData.personas &&
      pageData.personas.length > 0
    ) {
      const pageName = generateBreadcrumbName(pageData.metaTitle);
      const pageUrl = "https://asuonline.asu.edu/admission/";
      const admissionTypeOffers: WebPage[] = [];
      pageData.personas.forEach((type) => {
        if (type && type.title && type.text && type.image) {
          admissionTypeOffers.push({
            "@type": "WebPage",
            name: type.title,
            description: type.text,
            image: type.image,
            provider: getOrganizationSchema(),
          });
        } else {
          console.error(
            "Schema: Admission page persona missing title, text, or image."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageName,
        description: pageData.metaDescription,
        url: pageUrl,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: pageName,
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: admissionTypeOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Admission page data missing.");

      if (!pageData?.metaDescription)
        console.error("Schema: Admission page title missing.");
      if (!pageData?.personas)
        console.error("Schema: Admission page missing personas.");
      if (!pageData?.contentHub)
        console.error("Schema: Admission page breadcrumb missing.");

      return errorScriptValue;
    }
  }

  // interest-area template page's schema
  function generateInterestAreaTemplateSchema(
    pageData: InterestArea | undefined,
    programs: DegreeSeekingProgram[] | undefined
  ) {
    if (
      pageData &&
      pageData.title &&
      pageData.categoryBySlug &&
      pageData.metaDescription &&
      pageData.heroImage
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData?.categoryBySlug?.detailPage}`
      );

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "@id": UtilityService.formatCanonicalUrl(
          `https://asuonline.asu.edu${pageData?.categoryBySlug?.detailPage}`
        ),
        name: pageData.title,
        description: pageData.metaDescription,
        image: pageData.heroImage,
        url,
        breadcrumb: {
          // "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Areas of study",
              item: "https://asuonline.asu.edu/study/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: pageData.title,
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: generateInterestAreaTemplateOffers(pageData, programs),
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Interest Area page data missing.");

      if (!pageData?.title)
        console.error("Schema: Interest Area missing alt title missing.");
      if (!pageData?.categoryBySlug)
        console.error("Schema: Interest Area missing url missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Interest Area missing desc. missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Interest Area missing hero image missing.");

      return errorScriptValue;
    }
  }

  // study page's schema
  function generateStudyPageSchema(
    pageData: Study | undefined,
    studyAreas: InterestAreaType[] | undefined
  ) {
    if (
      pageData &&
      pageData.metaTitle &&
      pageData.detailPage &&
      studyAreas &&
      studyAreas.length > 0 &&
      pageData.metaDescription &&
      pageData.heroImage
    ) {
      const pageName = generateBreadcrumbName(pageData.metaTitle);
      const pageUrl = `https://asuonline.asu.edu${pageData.detailPage}`;
      const studyOffers: WebPage[] = [];

      studyAreas.forEach((item) => {
        if (
          item &&
          item.title &&
          item.description &&
          item.image &&
          item.detailPage
        ) {
          studyOffers.push({
            "@type": "WebPage",
            name: item.title,
            description: item.description,
            url: UtilityService.formatCanonicalUrl(
              `https://asuonline.asu.edu${item.detailPage}`
            ),
            image: item.image,
            provider: getOrganizationSchema(),
          });
        } else {
          console.error(
            "Schema: Study page study area missing title, description, image, or detail page."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageName,
        description: pageData.metaDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: pageName,
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: studyOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Study page page data missing.");

      if (!studyAreas) console.error("Schema: Study page study areas missing.");
      if (!pageData?.title) console.error("Schema: Study page title missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Study page hero meta title missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Study page description missing.");
      if (!pageData?.heroImage)
        console.error("Schema: Study page image missing.");
      if (!pageData?.detailPage)
        console.error("Schema: Study page detail page missing.");

      return errorScriptValue;
    }
  }

  // program template page's schema
  function generateProgramTemplateSchema(
    url: string,
    program:
      | ProgramUndergraduate
      | ProgramExploratory
      | ProgramCertificateGraduate
      | ProgramGraduate
      | null
      | undefined,
    featuredCourseItems: Maybe<ParagraphFeaturedCourses>[],
    category: string,
    totalCreditHours?: string,
    repeatCount?: number
  ) {
    const pageUrl = UtilityService.formatCanonicalUrl(url);
    const categoryTitle = category.toLowerCase();
    const faqItems: Question[] = [];
    if (program?.faqItems && Array.isArray(program?.faqItems)) {
      program?.faqItems.forEach((faqItem) => {
        if (faqItem?.title && faqItem.description) {
          faqItems.push({
            "@type": "Question",
            name: faqItem.title,
            acceptedAnswer: {
              "@type": "Answer",
              text: faqItem.description,
            },
          });
        } else {
          console.error("Schema: FAQ item missing title or description.");
        }
      });
    }
    if (program) {
      if (!program.alternateTitle) console.error(url, "missing alt title.");
      if (!program.shortDescription) console.error(url, "missing short desc.");
      if (!program.longDescription) console.error(url, "missing long desc.");
      if (!program.degreeImage) console.error(url, "missing degree image.");

      const schema: WithContext<WebPage> = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: program.alternateTitle ? program.alternateTitle : "",
        description: program.shortDescription ? program.shortDescription : "",
        disambiguatingDescription: program.longDescription
          ? program.longDescription
          : "",
        url: pageUrl,
        image: program.degreeImage ? program.degreeImage : "",
        offers: generateProgramTemplateOffers(
          featuredCourseItems,
          totalCreditHours!,
          repeatCount!
        ),
        provider: getOrganizationSchema(),
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "All online degree programs",
              item: "https://asuonline.asu.edu/online-degree-programs/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: `Online ${categoryTitle} programs`,
              item: UtilityService.formatCanonicalUrl(
                `https://asuonline.asu.edu/online-degree-programs/${categoryTitle}/`
              ),
            },
            {
              "@type": "ListItem",
              position: 4,
              name: program.alternateTitle ? program.alternateTitle : "",
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        mainEntity:
          faqItems.length > 0
            ? {
                "@type": "FAQPage",
                mainEntityOfPage: pageUrl,
                name: program.alternateTitle ? program.alternateTitle : "",
                description: program.shortDescription
                  ? program.shortDescription
                  : "",
                url: url,
                image: program.degreeImage ? program.degreeImage : "",
                mainEntity: faqItems,
              }
            : undefined,
      };

      if (program.bammProgram && program.bammProgram.cipCode) {
        schema["identifier"] = [
          {
            "@type": "PropertyValue",
            propertyID: "CIP2010",
            value: program.bammProgram.cipCode,
          },
        ];
      }

      return JsonLdNuxtHead<WebPage>(schema);
    } else {
      console.error(url, "missing program information while generating schema");
      return errorScriptValue;
    }
  }

  // persona template schema
  function generatePersonaSchema(pageData: Persona | undefined) {
    if (
      pageData &&
      pageData.detailPage &&
      pageData.applyCards &&
      pageData.applyCards.length > 0 &&
      pageData.newsArticles &&
      pageData.title &&
      pageData.introductionDescription &&
      pageData.heroImage
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageOffers: Array<WebPage | SchemaNewsArticle> = [];

      pageData.applyCards.forEach((item) => {
        if (item && item.link && item.link.uri && item.title) {
          pageOffers.push({
            "@type": "WebPage",
            "@id": `https://asuonline.asu.edu${item.link.uri}`,
            name: item.title,
          });
        } else {
          console.error("Schema: Persona apply card missing link or title.");
        }
      });

      const faqItems: Question[] = [];
      if (pageData.faqItems && Array.isArray(pageData.faqItems)) {
        pageData.faqItems.forEach((faqItem) => {
          if (faqItem?.title && faqItem.description) {
            faqItems.push({
              "@type": "Question",
              name: faqItem.title,
              acceptedAnswer: {
                "@type": "Answer",
                text: faqItem.description,
              },
            });
          } else {
            console.error("Schema: FAQ item missing title or description.");
          }
        });
      }
      // news article offers
      pageData.newsArticles.forEach((article) => {
        if (
          article &&
          article.title &&
          article.detailPage &&
          article.coverImage &&
          article.updatedDate
        ) {
          pageOffers.push({
            "@type": "NewsArticle",
            publisher: getOrganizationSchema(),
            headline: article.title,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": UtilityService.formatCanonicalUrl(
                `https://asuonline.asu.edu${article.detailPage}`
              ),
            },
            image: article.coverImage,
            datePublished: article.created
              ? appendIsoToDate(article.created)
              : new Date().toISOString(),
            dateModified: appendIsoToDate(article.updatedDate),
            author: getOrganizationSchema(),
          });
        } else {
          console.error(
            "Schema: Persona news article missing title, url, cover image, or updated date"
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageData.title,
        description: pageData.introductionDescription,
        url: pageUrl,
        image: pageData.heroImage,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Admission Requirements",
              item: "https://asuonline.asu.edu/admission/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: pageData.title,
              item: pageUrl,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        // Include FAQ schema inside the `mainEntity` field
        mainEntity: {
          "@type": "FAQPage",
          mainEntityOfPage: pageUrl,
          name: pageData.title,
          url: pageUrl,
          image: pageData.heroImage,
          mainEntity: faqItems,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Persona page data missing.");
      if (!pageData?.detailPage)
        console.error("Schema: Persona page detail page missing.");
      if (!pageData?.applyCards)
        console.error("Schema: Persona page apply cards missing.");
      if (!pageData?.newsArticles)
        console.error("Schema: Persona page news articles missing.");
      if (!pageData?.title)
        console.error("Schema: Persona page title missing.");
      if (!pageData?.introductionDescription)
        console.error("Schema: Persona page missing intro desc.");
      if (!pageData?.heroImage)
        console.error("Schema: Persona page missing image.");
      return errorScriptValue;
    }
  }
  // home page's schema
  function generateHomePageSchema() {
    const edOrgSchema = getOrganizationSchema() as Exclude<
      EducationalOrganization,
      string
    >;
    return JsonLdNuxtHead<EducationalOrganization>({
      "@context": "https://schema.org",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://asuonline.asu.edu/",
      },
      ...edOrgSchema,
    });
  }

  // generate minor program schema
  function generateMinorProgramSchema(
    title: string,
    detailPage: string,
    metaDescription: string,
    metaTitle: string
  ) {
    const pageUrl = UtilityService.formatCanonicalUrl(
      `https://asuonline.asu.edu${detailPage}`
    );

    return JsonLdNuxtHead<WebPage>({
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: title,
      description: metaDescription,
      url: pageUrl,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "ASU Online",
            item: "https://asuonline.asu.edu/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: generateBreadcrumbName(metaTitle),
            item: pageUrl,
          },
        ],
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": pageUrl,
      },
      provider: getOrganizationSchema(),
    });
  }

  function generateNewsroomPageSchema(
    pageData: NewsroomHome | undefined,
    newsArticles: NewsArticle[] | undefined,
    newsCategories: NewsCategory[] | undefined
  ) {
    if (
      pageData &&
      pageData.metaTitle &&
      pageData.metaDescription &&
      newsArticles &&
      newsCategories
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );
      const pageName = pageData.metaTitle.split("|")[0].trim();

      const pageOffers: Array<WebPage | SchemaNewsArticle> = [];

      // recent news-articles - article
      newsArticles.forEach((article) => {
        // ensure required article fields are present
        if (
          article.title &&
          article.detailPage &&
          article.coverImage &&
          article.publishedDate &&
          article.updatedDate &&
          article.timeToRead
        ) {
          const articleUrl = UtilityService.formatCanonicalUrl(
            `https://asuonline.asu.edu${article.detailPage}`
          );
          pageOffers.push({
            "@type": "NewsArticle",
            publisher: getOrganizationSchema(),
            headline: article.title,
            image: article.coverImage,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": articleUrl,
            },
            datePublished: appendIsoToDate(article.publishedDate),
            dateModified: appendIsoToDate(article.updatedDate),
            author: getOrganizationSchema(),
          });
        } else {
          console.error(
            "Schema: Newsroom home article missing title, detailPage, image, or cardDescription."
          );
        }
      });

      // news-categories - WebPage
      newsCategories.forEach((category) => {
        // ensure required category fields are present
        if (
          category.title &&
          category.detailPage &&
          category.image &&
          category.cardDescription
        ) {
          const categoryPageUrl = UtilityService.formatCanonicalUrl(
            `https://asuonline.asu.edu${category.detailPage}`
          );
          pageOffers.push({
            "@type": "WebPage",
            "@id": categoryPageUrl,
            name: category.title,
            url: categoryPageUrl,
          });
        } else {
          console.error(
            "Schema: Newsroom home category missing title, detailPage, image, or cardDescription."
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageName,
        description: pageData.metaDescription,
        url,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: pageName,
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: Newsroom home page data missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: Newsroom home page detail page missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: Newsroom home page detail page missing.");
      if (!pageData?.detailPage)
        console.error("Schema: Newsroom home page detail page missing.");
      if (!newsArticles)
        console.error("Schema: Newsroom home page news articles missing.");
      if (!newsCategories)
        console.error("Schema: Newsroom home page news categories missing.");
      return errorScriptValue;
    }
  }

  function generateNewsCategorySchema(pageData: NewsCategory | undefined) {
    if (
      pageData &&
      pageData.metaTitle &&
      pageData.metaDescription &&
      pageData.detailPage &&
      pageData.newsArticles
    ) {
      const url = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );
      const pageName = pageData.metaTitle.split("|")[0].trim();

      const pageOffers: Array<WebPage | SchemaNewsArticle> = [];
      // recent news-articles - article
      pageData.newsArticles.forEach((article) => {
        if (
          article &&
          article.title &&
          article.detailPage &&
          article.coverImage &&
          article.updatedDate
        ) {
          pageOffers.push({
            "@type": "NewsArticle",
            publisher: getOrganizationSchema(),
            headline: article.title,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": UtilityService.formatCanonicalUrl(
                `https://asuonline.asu.edu${article.detailPage}`
              ),
            },
            image: article.coverImage,
            datePublished: article.created
              ? appendIsoToDate(article.created)
              : new Date().toISOString(),
            dateModified: appendIsoToDate(article.updatedDate),
            author: getOrganizationSchema(),
          });
        } else {
          console.error(
            "Schema: News Category news article missing title, url, cover image, or updated date"
          );
        }
      });

      return JsonLdNuxtHead<WebPage>({
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: pageName,
        description: pageData.metaDescription,
        url,
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "ASU Online",
              item: "https://asuonline.asu.edu/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Newsroom",
              item: "https://asuonline.asu.edu/newsroom/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: pageName,
              item: url,
            },
          ],
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        offers: {
          "@type": "Offer",
          itemOffered: pageOffers,
        },
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: News Category page data missing.");
      if (!pageData?.metaTitle)
        console.error("Schema: News Category page detail page missing.");
      if (!pageData?.metaDescription)
        console.error("Schema: News Category page detail page missing.");
      if (!pageData?.detailPage)
        console.error("Schema: News Category page detail page missing.");
      if (!pageData?.newsArticles)
        console.error("Schema: News Category page news articles missing.");
      return errorScriptValue;
    }
  }

  function generateNewsArticleSchema(pageData: NewsArticle | undefined) {
    if (
      pageData &&
      pageData.metaTitle &&
      pageData.metaDescription &&
      pageData.title &&
      pageData.coverImage &&
      pageData.publishedDate &&
      pageData.updatedDate
    ) {
      const pageUrl = UtilityService.formatCanonicalUrl(
        `https://asuonline.asu.edu${pageData.detailPage}`
      );

      const pageName = pageData?.metaTitle?.split("|")[0].trim();

      return JsonLdNuxtHead<SchemaNewsArticle>({
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        name: pageName,
        description: pageData.metaDescription,
        url: pageUrl,
        publisher: getOrganizationSchema(),
        headline: pageData.title,
        image: pageData.coverImage,
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": pageUrl,
        },
        datePublished: appendIsoToDate(pageData.publishedDate),
        dateModified: appendIsoToDate(pageData.updatedDate),
        provider: getOrganizationSchema(),
      });
    } else {
      if (!pageData) console.error("Schema: News article page data missing.");

      if (!pageData?.metaTitle)
        console.error("Schema: News article page meta title missing.");
      if (!pageData?.title)
        console.error("Schema: News article page headline missing.");
      if (!pageData?.coverImage)
        console.error("Schema: News article page missing cover image.");
      if (!pageData?.publishedDate)
        console.error("Schema: News article page missing published date.");
      if (!pageData?.updatedDate)
        console.error("Schema: News article page missing updated date.");

      return errorScriptValue;
    }
  }

  return {
    generateMinorProgramSchema,
    generateHomePageSchema,
    generateProgramTemplateSchema,
    generateStudyPageSchema,
    generateInterestAreaTemplateSchema,
    generateAdmissionPageSchema,
    generateAdmissionProfileSchema,
    generateMilitaryScholarshipPageSchema,
    generateNonDegreePageSchema,
    generateStudentsSchema,
    generateStudyAbroadSchema,
    generateStudentsCourseSchema,
    generateStudentsServicesSchema,
    generateStudentsSuccessTeamSchema,
    generateStudentsReviewsSchema,
    generateStudentsTutoringSchema,
    generateStudentsGraduationSchema,
    generateStudentsCommunitySchema,
    generateAllDegreeSearchSchema,
    generateCategorySearchSchema,
    generateAboutUsSchema,
    generateAboutUsContactSchema,
    generateAboutUsAccreditationSchema,
    generateAboutUsPoliciesSchema,
    generateAboutUsFaqSchema,
    generateAboutUsFacultySchema,
    generateAboutUsRankingsSchema,
    generateAboutUsWhyAsuoSchema,
    generateWhatItCostsPageSchema,
    generateWebAnalyticsPrivacy2Schema,
    generateFirstTimeFreshmanRegistrationRulesSchema,
    generateDegreeProgramRequestInfoThankYouSchema,
    generatePersonaSchema,
    getOrganizationSchema,
    generateBreadcrumbName,
    generateNewsroomPageSchema,
    generateNewsCategorySchema,
    generateNewsArticleSchema,
    JsonLdNuxtHead,
  };
};
